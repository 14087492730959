import { FC, useState } from 'react';

import Form from '@components/controls/Form';
import { BasicFieldProps } from '@components/controls/Form/BasicField';
import Popup from '@components/controls/Popup';

import { Button, Section, colors, scale, typography } from '@scripts/gds';
import { useFieldCSS, useMedia } from '@scripts/hooks';

import MailIcon from '@icons/24/mail.svg';

import { inputSchema } from './schema';

export const Input: FC<BasicFieldProps> = ({ field, ...props }) => {
    const { basicFieldCSS } = useFieldCSS();

    return (
        <input
            css={{
                ...basicFieldCSS,
                paddingLeft: scale(2),
                paddingRight: scale(21),
                height: '100%',
                border: `1px solid ${props?.meta?.error ? colors.danger : colors.secondaryOnDark} !important`,
                borderRadius: 6,
            }}
            {...field}
            {...props}
        />
    );
};

export default function FooterSubscribe() {
    const { md } = useMedia();

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <Section
            css={{
                backgroundColor: colors.backgroundMain,
                padding: `30px 0`,
            }}
        >
            <div
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: scale(8),
                    justifyContent: 'space-between',
                    [md]: { flexWrap: 'wrap', gap: scale(2) },
                }}
            >
                <div
                    css={{
                        width: '100%',
                        paddingRight: scale(2),
                        [md]: { order: 1, maxWidth: `calc(100% - ${scale(8)}px)`, paddingRight: 0 },
                    }}
                >
                    <p
                        css={{
                            ...typography('h3'),
                            lineHeight: '2rem',
                            paddingBottom: scale(1),
                        }}
                    >
                        Хочу быть в курсе акций и новинок
                    </p>
                </div>

                <div
                    css={{
                        width: '100%',
                        position: 'relative',
                        [md]: { maxWidth: '100%', order: 3 },
                    }}
                >
                    <Form
                        initialValues={{ email: '' }}
                        onSubmit={async ({ email }) => {
                            setIsPopupOpen(true);
                        }}
                        validationSchema={inputSchema}
                        css={{
                            height: scale(6),

                            padding: 2,
                        }}
                    >
                        <Form.FastField name="email" placeholder="Введите Ваш e-mail" css={{ height: '100%' }}>
                            <Input />
                        </Form.FastField>

                        <Button
                            data-testid="button-submit"
                            type="submit"
                            Icon={MailIcon}
                            theme="secondary"
                            css={{
                                position: 'absolute',
                                top: scale(1, true),
                                right: scale(1, true),
                                width: scale(20),
                                height: scale(5),
                            }}
                        >
                            Запросить
                        </Button>
                    </Form>
                </div>
            </div>

            <Popup isOpen={isPopupOpen} onRequestClose={() => setIsPopupOpen(false)} title="Подписка">
                <Popup.Body>
                    <p>Спасибо что подписались на рассылку новостей и акций.</p>
                    <br />
                    <p>
                        Secumarket.ru - отраслевого маркетплейса систем безопасности. Обещаем, что будем писать только о
                        самом интересном и важном. До связи!
                    </p>
                </Popup.Body>
            </Popup>
        </Section>
    );
}
